<template>
  <div class="content">
    <keep-alive>
      <component :is="selectedMenuItemComponent" />
    </keep-alive>
  </div>
</template>

<script>
import ArticleCreation from "./ArticleCreation.vue";
import DuplicateReduction from "./DuplicateReduction.vue";
import ModificationItem from "./ModificationItem.vue";

export default {
  name: "MainContent",
  props: {
    selectedMenuItem: {
      type: String,
      default:'',
      required: true,
    },
  },
  data() {
    return {
     
    };
  },
  computed: {
    selectedMenuItemComponent() {
      console.log("MainContent show " + this.selectedMenuItem);
      switch (this.selectedMenuItem) {
        case "智写":
          return ArticleCreation;
        case "降重":
          return DuplicateReduction;
        case "改写":
          return ModificationItem;
        default:
          return null;
      }
    },
  },
};
</script>

<style scoped>
.content {
  flex: 1;
  padding: 20px;
}
</style>
