// 打点地址
const dotURL = 'https://mbm.ktvcar.com/event';

// 打点函数
export function sendDot(k) {
  // 拼接打点参数
  const g = {
    user: getUserInfo(),
    timestamp: Math.floor(Date.now() / 1000).toString(),
    // deviceId: generateDeviceId(),
    // sessionId: generateSessionId(),
    pagePath: window.location.pathname,
    pageTitle: document.title,
    deviceInfo: getDeviceInfo(),
    // geolocation: getGeolocation(),
    referrer: document.referrer,
    platform: getPlatform(),
  };
  const queryString = `k=${encodeURIComponent(JSON.stringify(k))}&g=${encodeURIComponent(JSON.stringify(g))}`;
  const fullURL = `${dotURL}?${queryString}`;

  // 发送打点请求
  fetch(fullURL)
    .then(response => {
      // 处理打点请求的响应
      console.log(response);
      console.log('打点请求已发送');
    })
    .catch(error => {
      // 处理打点请求的错误
      console.error('打点请求发送失败', error);
    });
}


// // 生成设备唯一标识
// function generateDeviceId() {
//     // 假设此处使用设备指纹库或其他方法生成设备唯一标识
//     const deviceId = 'abcdef123456';
//     return deviceId;
//   }
  
//   // 生成会话标识
//   function generateSessionId() {
//     // 假设此处使用随机生成的会话标识
//     const sessionId = Math.random().toString(36).substr(2, 10);
//     return sessionId;
//   }
  
  // 获取设备信息
  function getDeviceInfo() {
    const deviceInfo = `${navigator.platform}, ${navigator.userAgent}`;
    return deviceInfo;
  }
  
  // 获取地理位置信息
  // function getGeolocation() {
  //   // 假设此处获取地理位置的方法是异步的，使用示例数据代替
  //   const latitude = 40.7128;
  //   const longitude = -74.0060;
  //   const geolocation = `${latitude}° N, ${longitude}° W`;
  //   return geolocation;
  // }
  
  // 获取平台信息
  function getPlatform() {
    const isMobile = /Mobile|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
    const platform = isMobile ? 'Mobile' : 'Web';
    return platform;
  }
  
  

function getUserInfo() {
  var loginStatus = localStorage.getItem('loginStatus');

  // 检查登录状态
  if (loginStatus) {
    // 用户已登录，可以继续进行操作
    var userData = JSON.parse(loginStatus);
    return {
      user: userData.data.name,
      tel: userData.data.mobile,
    }
  } else {
    return {};
  }
}


