class ResponseParser {
    constructor() {
      this.reader = null;
      this.decoder = new TextDecoder();
      this.buffer = '';
      this.reset();
    }
  
    stopReadingAndExit() {
      console.log("stopReadingAndExit");
      if(this.reader){
        this.reader.cancel();
      }
      this.stopReading = true;
    }

    reset(){
      this.stopReading = false;
    }

    setCallback(cb){
        this.callback = cb;
    }

    isNullCallback(){
      return this.callback == null;
    }
  
    async parseResponseData(response) {
      this.reader = response.body.getReader();
  
      const processStream = async ({ done, value }) => {
        if (this.stopReading) {
          console.log("this.stopReading == true");
          return;
        }
        if (done) {
          if (this.buffer !== '' && !this.buffer.includes('[DONE]')) {
            const responseData = JSON.parse(this.buffer);
            this.callback(responseData, false);
          } else if (this.buffer.includes('[DONE]')) {
            console.log('done');
            this.callback('', true);
          }
          return;
        }
  
  
        const chunk = this.decoder.decode(value, { stream: true });
        const jsonDataList = (this.buffer + chunk).split('data:');
        this.buffer = jsonDataList.pop();
  
        for (const jsonData of jsonDataList) {
          if (jsonData.trim() === '') continue;
          const jsonStartIndex = jsonData.indexOf('{');
          const jsonEndIndex = jsonData.lastIndexOf('}');
          const jsonStr = jsonData.substring(jsonStartIndex, jsonEndIndex + 1);
          if(jsonStr.trim() === ''){
            continue;
          }
          const responseData = JSON.parse(jsonStr);
          if (responseData.choices[0].delta.content) {
            this.callback(responseData, false);
          }
        }
  
        if (!this.stopReading) {
          return this.reader.read().then(processStream);
        }
      };
      if(!this.stopReading){
        return this.reader.read().then(processStream);
      }
    }
  }
  
  
  export default ResponseParser;