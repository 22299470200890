<template>
  <button :disabled="countdown > 0" :class="['countdown-btn', { 'disabled': countdown > 0 }]" >
    <template v-if="countdown === 0">{{ buttonText }}</template>
    <template v-else>剩余{{ countdown }}s</template>
  </button>
</template>

<script>
export default {
  props: {
    buttonText: {
      type: String,
      required: true
    },
    duration: {
      type: Number,
      default: 60
    }
  },
  data() {
    return {
      countdown: 0
    };
  },
  methods: {
    startCountdown() {
      if (this.countdown > 0) {
        return;
      }

      this.countdown = this.duration;
      const timer = setInterval(() => {
        this.countdown--;
        if (this.countdown === 0) {
          clearInterval(timer);
        }
      }, 1000);
    }
  }
};
</script>

<style>
.countdown-btn {
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100px;
}

.countdown-btn.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
</style>
