<template>
  <div class="container">
    <div class="container-header">
      <div class="container-header-center">AI智写助手1.0</div>
      <div class="user-center">
        <span
          class="user-center-item"
          @mouseover="showBalanceMenu"
          @mouseleave="hideBalanceMenu"
        >
          {{ userName }}
        </span>
        <div v-if="showBalance" class="balance-menu">
          金币:{{ goldAmount }}
        </div>
        <button @click="showRechargeModal" class="recharge-btn">充值</button>
        <span
          class="user-center-item-logout"
          v-if="isLogin"
          @click="logout"
        >
          退出
        </span>
      </div>
    </div>
    <MainSidebar
      class="container-content-sidebar"
      @menuItemSelected="handleMenuItemSelected"
    />
    <MainContent
      class="container-content-content"
      :selectedMenuItem="selectedMenuItem"
    />
    <div class="container-footer">
      <p class="p-dis-copyright">Copyright©2016-2023</p>
      <div class="illustrate-content">
        <p class="p-dis-text">1、本软件为学习或测试使用，生成的所有内容仅供参考，请勿进行公开发表或使用</p>
        <p class="p-dis-text">2、请注意遵守相关法律法规，避免使用敏感词汇</p>
        <p class="p-dis-text">3、生成内容不包含实证类、实验类、模型训练类、数据分析类和案例分析类</p>
      </div>
    </div>

    <RechargeModal
      :showModal="showModal"
      :rechargePackages="rechargePackages"
      @closeModal="closeRechargeModal"
      @buyPackage="buyPackage"
    />

    <PurchaseModal
      :showModal="showPurchaseModal"
      :purchaseCodeUrl="purchaseCodeUrl"
      @closeModal="closePurchaseModal"
      @checkPaymentStatus="checkPaymentStatus"
    />
  </div>
</template>

<script>
import MainSidebar from './MainSidebar.vue';
import MainContent from './MainContent.vue';
import RechargeModal from './RechargeModal.vue';
import PurchaseModal from './PurchaseModal.vue';
import {showToast} from '@/js/toast'

export default {
  name: 'MainPage',
  components: {
    MainSidebar,
    MainContent,
    RechargeModal,
    PurchaseModal,
  },
  data() {
    return {
      selectedMenuItem: '',
      isLogin: false,
      userName: 'John Doe', // 替换为实际的用户名
      showModal: false,
      showBalance: false,
      goldAmount: 0,
      rechargePackages: [
        // {
        //   id: 1,
        //   name: '套餐1',
        //   amount: 10,
        //   image: 'package1.jpg',
        // },
        // {
        //   id: 2,
        //   name: '套餐2',
        //   amount: 20,
        //   image: 'package2.jpg',
        // },
        // 添加更多套餐数据...
      ],
      showPurchaseModal: false,
      purchaseCodeUrl: '',
      orderSn: '',
      paymentStatusInterval: null,
    };
  },
  methods: {
    showBalanceMenu() {
      this.getUserInfo(
        ()=> {
          // onSuccess 回调逻辑
          this.showBalance = true;
        },
        ()=> {
          // onFailed 回调逻辑
          this.showBalance = false;
        }
      );
    },
    hideBalanceMenu() {
      this.showBalance = false;
    },
    showRechargeModal() {
      this.showModal = true;
      // this.getUserInfo(
      //   () => {
      //     // onSuccess 回调逻辑
      //     this.showModal = true;
      //   },
      //   () => {
      //     // onFailed 回调逻辑
      //     this.showModal = false;
      //   }
      // );
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(),
      };
      fetch('https://musicgpt.mbmzone.com/api-thesis/chargeFeel/queryAll', requestOptions)
        .then(response => {
          if (response.status === 404) {
            throw new Error('Not Found');
          }
          return response.json()
        })
        .then(data => {
          // 处理返回的套餐数据
          this.rechargePackages = data.data;
          this.showModal = true;
        })
        .catch(error => {
          console.error('Error:', error);
        });
    },
    closeRechargeModal() {
      this.showModal = false;
    },
    handleMenuItemSelected(item) {
      this.selectedMenuItem = item;
    },
    logout() {
      // 用户退出逻辑
      // ...
      // 清除登录状态
      localStorage.removeItem('loginStatus');
      this.$router.push('/login');
    },
    checkLoginStatus() {
      var loginStatus = localStorage.getItem('loginStatus');

      // 检查登录状态
      if (loginStatus) {
        // 用户已登录，可以继续进行操作
        var userData = JSON.parse(loginStatus);
        // console.log(userData);
        this.userName = userData.data.name;
        this.isLogin = true;
      } else {
        // 用户未登录，跳转到登录页
        this.isLogin = false;
        this.$router.push('/login');
      }
    },
    getUserInfo(onSuccess, onFailed) {
      var loginStatus = localStorage.getItem('loginStatus');
      // 检查登录状态
      if (loginStatus) {
        // 用户已登录，可以继续进行操作
        var userData = JSON.parse(loginStatus);
        // console.log(userData);
        var accessKey = userData.data.accessKey;
        var token = userData.data.token;
      }
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      };
      fetch(
        `https://musicgpt.mbmzone.com/api-thesis/user/doGetInfo?accessKey=${accessKey}`,
        requestOptions
      )
      .then(response => response.json())
      .then((data) => {
          if (data.code === 11000) {
            this.goldAmount = data.data.goldAmount;
            // 请求成功，调用 onSuccess 回调
            console.log('Request succeeded!');
            onSuccess();
          } else {
            // 请求成功但未满足条件，调用 onFailed 回调
            console.log('Request failed!');
            onFailed();
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          // 请求失败，调用 onFailed 回调
          console.log('Request error!');
          onFailed();
        });
    },
    
    buyPackage(rechargePackage) {
      // 点击套餐图片，购买套餐
      const chargeFeelId = rechargePackage.id;

      var loginStatus = localStorage.getItem('loginStatus');
      // 检查登录状态
      if (loginStatus) {
        // 用户已登录，可以继续进行操作
        var userData = JSON.parse(loginStatus);
        // console.log(userData);
        var accessKey = userData.data.accessKey;
      }
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ accessKey, chargeFeelId }),
      };

      fetch('https://musicgpt.mbmzone.com/api-thesis/wechatUserPay', requestOptions)
        .then((response) => response.json())
        .then((data) => {
          // 处理返回的购买结果数据
          if (data.code === 11000) {
            // 设置订单号
            this.orderSn = data.data.orderNo;
            this.purchaseCodeUrl = data.data.codeUrl;
            this.showPurchaseModal = true;
            // 开始定时请求支付详情接口
            this.paymentStatusInterval = setInterval(()=>{
              this.checkPaymentStatus();
            }, 3000);
          } else {
            console.error('Purchase failed:', data.msg);
          }
        })
        .catch((error) => {
          // 处理异常提示
          this.showPurchaseModal = true;
          console.error('Error:', error);
        });
    },
    checkPaymentStatus() {
      // 如果订单号为空，则停止请求支付详情接口
      console.log("checkPaymentStatus");
      if (!this.orderSn) {
        return;
      }

      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };
      fetch(
        `https://musicgpt.mbmzone.com/api-thesis/user/charge/order/details?orderSn=${this.orderSn}`,
        requestOptions
      )
        .then((response) => {
          if (response.status === 404) {
            throw new Error('Not Found');
          }
          return response.json();
        })
        .then((data) => {
          // 处理返回的支付详情数据
          if (data.code === 11000) {
            const paymentStatus = data.data.status;
            if (paymentStatus == 1) {
              // 支付成功
              this.showPurchaseModal = false;
              // 执行支付成功的逻辑，例如弹出提示框
              showToast("支付成功");
              // 停止定时请求支付详情接口
              clearInterval(this.paymentStatusInterval);
            } else if (paymentStatus == 2 || paymentStatus == 3) {
              // 支付关闭或支付错误
              this.showPurchaseModal = false;
              // 执行支付关闭或支付错误的逻辑
              showToast('支付关闭或支付错误');
              // 停止定时请求支付详情接口
              clearInterval(this.paymentStatusInterval);
            }
          } else {
            console.error('Error:', data.msg);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    },
    closePurchaseModal() {
      this.showPurchaseModal = false;
      // 停止定时请求支付详情接口
      clearInterval(this.paymentStatusInterval);
    },
  },
  created() {
    this.checkLoginStatus();
  },
};
</script>

  <style>
.container {
  /* display: flex;
  flex-direction: column;
  height: 100%; */
  
}

.container-header {
  position: fixed;
  background-color: #bebbb9;
  color: #645f5f;
  font-weight: bold;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-content-sidebar{
  position: fixed;
  top: 50px;
  left: 0;
  width: 200px;
  height: calc(100vh - 50px);
  background-color: rgb(71, 89, 104);
  overflow-y: auto;
}
.container-content-content{
  margin-left: 200px; /* 根据侧边栏宽度调整 */
  margin-top: 50px;
  margin-bottom: 50px;
  width: 50%;
  

}

.container-footer{
  position: fixed;
  background-color: #bebbb9;
  color:rgb(60, 57, 57);
  bottom: 0;
  left: 0;
  width: 100%;
  height: fit-content;
  z-index: 1; 
  position: fixed;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  padding: 5px 0px;
}

.user-center {
  text-align: center;
  position: absolute;
  right: 10px;
  width: fit-content;
  padding: 10px;
}

.user-center-item{
  margin-left: 10px;
  margin-right: 10px;
}
.user-center-item-logout{
  margin-left: 10px;
  margin-right: 10px;
  text-decoration:underline;
}


.balance-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  padding: 5px;
}
.p-dis-text{
  margin: 0px;
}

.p-dis-copyright{
  text-align: center;
  margin: 0px;
}

  </style>
  