<template>
  <div class="login-container">
    <div class="login-box">
      <h2>AI智写助手登录</h2>
      <div class="form-group">
        <input type="text" id="phone" v-model="phone" placeholder="请输入手机号" class="input">
      </div>
      
      <div class="form-group">
        <input type="text" id="verificationCode" v-model="verificationCode" placeholder="请输入验证码" class="input">
        <CountdownButton class="invite-style" ref="countdownButton" buttonText="获取验证码" @click="sendCode" duration="120"/>
      </div>
      <button @click="login" class="login-btn">登录</button>
      
      <div class="form-group">
        <p class="register-link" @click="goToRegister">没有账号？立即注册</p>
      </div>
    </div>
  </div>
</template>

<script>
import {showToast} from '@/js/toast'
import CountdownButton from '@/components/CountdownButton.vue';
import { sendDot } from '@/js/dot';

export default {
  data() {
    return {
      phone: '',
      verificationCode: '',
    };
  },  
  components:{
    CountdownButton,    
  },
  methods: {
    sendCode() {
      if(!this.phone){
        this.showToastMessage("手机号为空");
        return;
      }
      // 调用接口发送验证码
      fetch('https://musicgpt.mbmzone.com/api-thesis/user/doSendCode', {
        method: 'POST',
        body: JSON.stringify({
          phone: this.phone
        })
      })
        .then(response => response.json())
        .then(data => {
          if (data.code == 11000) {
            this.showToastMessage('验证码发送成功');
            this.$refs.countdownButton.startCountdown();
          } else {
            console.log(data.msg);
            this.showToastMessage(data.msg);
          }
        })
        .catch(error => {
          this.showToastMessage('验证码发送失败');
          console.log(error)
        });
    },
    login() {
      if(!this.phone || !this.verificationCode){
        this.showToastMessage("手机号 或者 验证码为空");
        return;
      }
      // 调用接口进行登录
      fetch('https://musicgpt.mbmzone.com/api-thesis/user/doLogin', {
        method: 'POST',
        body: JSON.stringify({
          code: this.verificationCode,
          inviteCode: '',
          phone: this.phone
        })
      })
        .then(response => response.json())
        .then(data => {
          if (data.code == 11000) {
            sendDot({
              type: 'loginSuccess',
              phone: this.phone,
              code: this.verificationCode,
              inviteCode: '',
            });
            // 登录成功，跳转到ArticleCreation.vue页面
            this.$router.push('/');
            // 保存登录状态
            this.saveLoginStatus(data);
          } else {
            this.showToastMessage(data.msg);
          }
        })
        .catch(error => {
          this.showToastMessage('登录失败');
          console.log(error);
        });
    },
    // 显示 Toast 提示框
    showToastMessage(message) {
      showToast(message);
    },
    // 登录成功后保存登录状态
    saveLoginStatus(data) {
      localStorage.setItem('loginStatus', JSON.stringify(data));
    },
    goToRegister() {
      // 跳转到注册页面
      sendDot({
            type: 'regPage',
          });
      this.$router.push('/register');
    },

  }
};
</script>

<style>
.login-container {
  display: flex;
  justify-content: center;
  align-items: top;
  height: 100vh;
}

.login-box {
  width: 300px;
  height: fit-content;
  padding: 5px 20px 5px;
  background-color: #f8f8f8;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  margin-top: 15vh; /* 调整登录框位置 */
}

h2 {
  text-align: center;
  margin-top: 2px;
  color: #333;
}

.form-group {
  margin-bottom: 20px;
  display: flex;
  align-items: center; /* 水平对齐手机号标签和编辑框 */
}

.label {
  display: flex;
  margin: 5px;
  
  font-weight: bold;
  color: #333;
  white-space: nowrap; /* 禁止换行 */
}

.input {
  flex: 1; /* 自适应宽度 */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btn {
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin:5px;
}

.login-btn {
  display: block;
  margin: 0 auto; /* 居中显示 */
  margin-bottom: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%; /* 设置宽度为100% */

}
.register-link {
  text-align: center;
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 20px;
  font-size: 10px;
}

.register-link:hover {
  color: #007bff;
}

.invite-style{
    margin-left: 10px;
  }
</style>
