class ContentTimer {
    constructor(timeout) {
      this.timeout = timeout;
      this.timer = null;
      this.subscribers = [];
    }
  
    start() {
      this.timer = setInterval(() => {
        this.handleTimeout();
      }, this.timeout);
    }
  
    stop() {
      clearInterval(this.timer);
    }
  
    reset() {
      this.stop();
      this.start();
    }
  
    subscribe(callback) {
      this.subscribers.push(callback);
    }
  
    unsubscribe(callback) {
      this.subscribers = this.subscribers.filter(subscriber => subscriber !== callback);
    }
  
    notifySubscribers() {
      this.subscribers.forEach(subscriber => subscriber());
    }
  
    handleTimeout() {
      this.notifySubscribers();
    }
  }

export default ContentTimer;