<template>
<div class="mod-content">
      <div class="section">
        <label for="field">文章题目：</label>
        <el-input  type="textarea" id="field" v-model="titleContent" placeholder="例如行政管理,限定10个汉字" rows="1"></el-input>
      </div>
  
      <div class="section">
        <label>修改意见：</label>
        <el-input type="textarea" v-model="suggestionContent" placeholder="请简明扼要，不超过50个汉字" rows="3"></el-input>
      </div>
      <div class="section">
        <label>背景信息：</label>
        <el-input type="textarea" v-model="backgroundContent" placeholder="1、如果需要修改的为一级标题，此处请输入完整大纲。
2、如果需要修改的为二级标题，此处请输入此二级标题所在的一级标题下的所有内容。" rows="3"></el-input>
      </div>
      <div class="section">
        <label>需调整的内容：</label>
        <el-input type="textarea" v-model="mainContent" placeholder="1、如果需要修改的为一级标题，此处请输入完整大纲。
2、如果需要修改的为二级标题，此处请输入此二级标题所在的一级标题下的所有内容。
" rows="5"></el-input>
      </div>
      <div class="section">
        <div class="generate-btn-container">
          <LoadingButton :is-loading="isGeneratingModifyContent" @click="generateModifyContent" :button-text="'改写'"
            :loading-text="'生成中'"></LoadingButton>
          <button class="btn-stop" @click="stopGenerateModArticle" v-if="isGeneratingModifyContent">结束生成</button>
        </div>
        <textarea type="textarea" v-model="resultContent" rows="8" placeholder="自动输出标题" ref="titleTextarea" 
          @scroll="handleScroll('titleTextarea')"></textarea>
      </div>  

    </div>
     
</template>

<script>
import LoadingButton from '@/components/LoadingButton.vue';
import ResponseParser from '@/js/ResponseParser';
import ContentTimer from '@/js/ContentTimer';
import {requestModifyContent} from '@/js/api'
import {countCharacters} from '@/js/utils'
import {showToast, showErrorToast} from '@/js/toast'

export default {
  name: 'ModificationItem',
  created() {
    this.checkLoginStatus();
    this.modifyParser = new ResponseParser();
    this.contentTimer = new ContentTimer(1000*10);// 10s 一个时钟间隔

  },
  mounted() {
    // 在组件挂载完成后，默认将编辑框滚动到底部
    this.scrollToBottom('titleTextarea');          
    this.contentTimer.start();
    this.contentTimer.subscribe(()=>{
      const nowTime = Date.now();
      if((nowTime - this.startTimeForArticle) > 2*60*1000 && this.isGeneratingArticle){
        showToast("生成正文响应有点慢");
      }

      if((nowTime - this.startTimeForOutline) > 2*60*1000 && this.isGeneratingOutline){
        showToast("生成大纲响应有点慢");
      }
    });

  },
  watch:{
    resultContent(newText) {
      return this.charCount = countCharacters(newText);
    },
  },
 
  components: {
    LoadingButton,
  },
  data() {
    return {
      suggestionContent: '',
      backgroundContent: '',
      mainContent: '',
      editable: false,

      titleContent: '',
   

      userName: '',
      amount: '',
      referralCode: '',
      
      accessKey:'',
      resultContent:'',

      isAutoScrolling: true, // 标记是否处于自动滚动状态
      


      isGeneratingModifyContent:false,
      // 其他数据...

      modifyParser: null,

      charCount:0,

 

      contentTimer:null,

      startTimeForArticle: 0,
      startTimeForOutline: 0,

    };
  },
  methods: {
    handleScroll(textareaRef) {
      const textarea = this.$refs[textareaRef];

      // 检查是否滑到最底部
      const isAtBottom = textarea.scrollTop + textarea.clientHeight === textarea.scrollHeight;

      // 如果用户手动滑动到最底部，则启动自动滚动
      if (isAtBottom && !this.isAutoScrolling) {
        this.isAutoScrolling = true;
        this.scrollToBottom(textareaRef);
      }

      // 如果用户手动滑动，则停止自动滚动
      if (!isAtBottom && this.isAutoScrolling) {
        // this.isAutoScrolling = false;
        this.isAutoScrolling = true;
      }
    },

    scrollToBottom(textareaRef) {
      const textarea = this.$refs[textareaRef];
      textarea.scrollTop = textarea.scrollHeight;
    },


    async generateModifyContent() {
      try {
        if(!this.backgroundContent || !this.suggestionContent || !this.mainContent){
          showToast("背景信息、建议内容、待修改内容均不能为空");
          return;
        }
        this.isGeneratingModifyContent = true;
        this.resultContent = '';
        const response = await requestModifyContent(this.accessKey, this.backgroundContent, this.suggestionContent, this.mainContent);
        this.modifyParser = new ResponseParser();
        this.modifyParser.reset();
        if (this.modifyParser.isNullCallback()) {
          this.modifyParser.setCallback((responseData, done) => {
            if (done) {
              this.isGeneratingModifyContent = false;
              if (this.resultContent.includes('出错啦！请重试')) {
                showErrorToast('生成内容出错');
              }
              return;
            }
            // console.log(responseData.choices[0].delta.content)
            this.resultContent += responseData.choices[0].delta.content;
            // 只有当处于自动滚动状态时才滚动到底部
            if (this.isAutoScrolling) {
              this.scrollToBottom('titleTextarea');
            }
          });
        }
        this.modifyParser.parseResponseData(response);
      } catch (error) {
        this.isGeneratingModifyContent = false;
        console.error('Error:', error);
      }
    },

    
    copyArticle() {
      if(!this.resultContent){
        showToast("文章为空");
        return;
      }
      const textarea = document.createElement('textarea');
      textarea.value = this.resultContent;
      textarea.setAttribute('readonly', '');
      textarea.style.position = 'absolute';
      textarea.style.left = '-9999px';
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      console.log("Text copied to clipboard");
      showToast("正文已复制");

    },
     // 在需要判断登录状态的地方调用该函数
     checkLoginStatus() {
      var loginStatus = localStorage.getItem('loginStatus');

      // 检查登录状态
      if (loginStatus) {
          // 用户已登录，可以继续进行操作
          var userData = JSON.parse(loginStatus);
          // console.log(userData);

          this.userName = userData.data.name;
          this.accessKey = userData.data.accessKey;

          // this.amount= userData.data.remainAmount;
          // this.referralCode = userData.data.inviteCode;
          // 在这里进行相关操作，例如生成标题、生成大纲、生成正文等
      } else {
          // 用户未登录，跳转到登录页
          this.$router.push('/login');
      }
    },

    
    stopGenerateModArticle(){
      console.log("stopGenerateModArticle");
      this.isGeneratingModifyContent = false;
      this.modifyParser.stopReadingAndExit();
      this.modifyParser = null;
      this.resultContent = '';
    },
  },
};




</script>

<style scoped>

.mod-content {

  /* width: 50%; */
}

.section {
  margin-bottom: 20px;
  margin-top: 0px;
  width: 100%;

  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: left;
  
}


label {
  text-align: left;
}

input[type="text"]{
  flex: 1;
  height: 20px;
}

textarea {
  overflow: auto;
}

.generate-btn-container{
  display: flex;
  flex-direction: row;
}



input[type="text"]::placeholder,
input[type="number"]::placeholder,
textarea::placeholder {
  opacity: 0.5;
  color: #999;
}




.btn-stop{
  margin-left: 10px;
}
</style>
