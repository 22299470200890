<template>
  <div>
    <h2>订单信息</h2>
    <p>订单号: {{ orderNumber }}</p>
    <p>金额: {{ amount }}</p>
    
    <h2>选择支付方式</h2>
    <v-radio-group v-model="paymentMethod">
      <v-radio label="支付宝" value="alipay"></v-radio>
      <v-radio label="微信支付" value="wechatpay"></v-radio>
    </v-radio-group>
    
    <v-btn @click="pay">支付</v-btn>
  </div>
</template>

<script>
export default {
  data() {
    return {
      orderNumber: '123456',
      amount: '100',
      paymentMethod: '',
    };
  },
  methods: {
    pay() {
      // 执行支付逻辑
      // 调用后端接口或支付服务提供商的API，发起支付请求
    },
  },
};
</script>
