<template>
    <div v-if="showModal" class="modal">
      <div class="purchasemodal-content">
        <div class="close-btn" @click="closeModal">x</div>
        <div ref="qrcodeContainer" class="qrcode-container">
          <img :src="purchaseCodeUrl" alt="充值二维码" class="qrcode-image" />
        </div>
      </div>
    </div>
</template>
  
<script>
  export default {
    props: {
      showModal: {
        type: Boolean,
        required: true,
      },
      purchaseCodeUrl: {
        type: String,
        required: true,
      },
    },
    methods: {
      closeModal() {
        this.$emit('closeModal');
      },
      checkPaymentStatus() {
        this.$emit('checkPaymentStatus');
      },
    },
    mounted() {
      // 在组件挂载后开始定时请求支付详情接口
      // this.paymentStatusInterval = setInterval(this.checkPaymentStatus, 3000);
    },
    beforeUnmount() {
    // 在组件销毁前停止定时请求支付详情接口
    // clearInterval(this.paymentStatusInterval);
  },
  };
</script>
  
<style>
.qrcode-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}
.qrcode-image {
  width: 150px;
  height: 150px;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
}
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  /* background-color: #ddd; */
  border-radius: 4px;
  cursor: pointer;
}
.purchasemodal-content {
  width: 200px;
  height: 200px;
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
}

</style>
  