<template>
    <div class="container">
      <h1>参考文献搜索&导出</h1>
      <!-- <div v-for="reference in references" :key="reference.id" class="card">
        <div class="card-text">{{ reference.title }}</div>
        <div class="card-image">
          <img :src="reference.imageSrc" :alt="reference.title" />
        </div>
      </div> -->
      <p><span class="key-content">第一步</span>，登录网址：<a class = 'p-url' href = 'https://xueshu.baidu.com'>https://xueshu.baidu.com</a>，将<span class="key-content">文章标题</span>复制进行搜索。</p>
      <p><span class="key-content">第二步</span>，在明细页选择与题目相关性较高的文章点击“<span class="key-content">批量引用</span>”。</p>
      <p><span class="key-content">第三步</span>，点击<span class="key-content">右下角小图标</span>，调出参考文献导出界面。</p>
      <div class="image-container">
        <img src="https://qncweb.ktvsky.com/20230605/other/041ef7811433b0e9d50f777886c8cf6b.png" />
        <img src="https://qncweb.ktvsky.com/20230605/other/0ae05fee3ce49f3cac74d214877d5b02.png" />
      </div>
      <p><span class="key-content">第四步</span>，选择文献导出格式“<span class="key-content">GB/T 7714</span>”（常见引用格式）。</p>
      <p><span class="key-content">第五步</span>，点击<span class="key-content">全选</span>，并点击<span class="key-content">复制</span>。</p>
      <p><span class="key-content">第六步</span>，复制到word中查看并<span class="key-content">保存</span>。</p>
      <div class="image-container">
        <img src="https://qncweb.ktvsky.com/20230605/other/c818396ba6ac47ae1e15d96c9716865f.png" />
        <img src="https://qncweb.ktvsky.com/20230605/other/301ba9524680c1a09b392a269c907b4b.png" />
      </div>
      
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        references: [
          {
            id: 1,
            title: "参考文献 1",
            imageSrc: "path/to/image1.jpg"
          },
          {
            id: 2,
            title: "参考文献 2",
            imageSrc: "path/to/image2.jpg"
          }
          // 添加更多的参考文献对象...
        ]
      };
    }
  };
  </script>
  
  <style>
.container{
  display: flex; /* 将容器设置为弹性盒子 */
  text-align: center;
  flex-direction: column;
}
.image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.image-container img {
  width: 800px; /* 将图片容器的宽度设置为三分之一，以便三张图片平分一行 */
  height: auto; /* 根据需要调整图片高度 */
}

.p-url{
  font-size:40px;
  font-weight:bold;
}

.key-content{
  color: #ea3a32;
  font-weight: bold;
}
</style>
  