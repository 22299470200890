<template>
    <div class="register-container">
      <div class="register-box">
        <h2>AI智写助手注册</h2>
        <div class="form-group">
          <input type="text" id="phone" v-model="userName" placeholder="请输入名字" class="input">
        </div>
        <div class="form-group">
          <input type="text" id="phone" v-model="phone" placeholder="请输入手机号" class="input">
        </div>
        
        <div class="form-group">
          <input type="text" id="verificationCode" v-model="verificationCode" placeholder="请输入验证码" class="input">
          <CountdownButton class="invite-style" ref="countdownButton" buttonText="获取验证码" @click="sendCode" duration="120"/>
        </div>
        <button @click="register" class="register-btn">注册</button>
        
        <div class="form-group">
          <p class="login-link" @click="goToLogin">已有账号？返回登录</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import {showToast} from '@/js/toast'
  import CountdownButton from '@/components/CountdownButton.vue';

  import { sendDot } from '@/js/dot';

  
  export default {
    data() {
      return {
        phone: '',
        verificationCode: '',
        userName:'',
      };
    },
    components:{
        CountdownButton,    
    },
    methods: {
      sendCode() {
        if (!this.phone) {
          this.disable = false;
          this.showToastMessage("手机号为空");
          return;
        }

        // 调用接口发送验证码
        fetch('https://musicgpt.mbmzone.com/api-thesis/user/doRegisterCode', {
          method: 'POST',
          body: JSON.stringify({
            phone: this.phone
          })
        })
          .then(response => response.json())
          .then(data => {
            console.log(data);
            if (data.code === 11000) {
              this.showToastMessage('验证码发送成功');
              this.$refs.countdownButton.startCountdown();
            } else {
              console.log(data.msg);
              this.showToastMessage(data.msg);
            }
          })
          .catch(error => {
            this.showToastMessage('验证码发送失败');
            console.log(error);
          });
      },
      register() {
        if (!this.phone || !this.verificationCode ||!this.userName) {
          this.showToastMessage("名字、手机号、验证码不能为空");
          return;
        }
        // 调用接口进行注册
        fetch('https://musicgpt.mbmzone.com/api-thesis/user/registerAccount', {
          method: 'POST',
          body: JSON.stringify({
            code: this.verificationCode,
            phone: this.phone,
            name:this.userName,
          })
        })
          .then(response => response.json())
          .then(data => {
            if (data.code === 11000) {
              this.showToastMessage('注册成功,跳转到登录页');
              sendDot({
                type: 'regSuccess',
                code: this.verificationCode,
                phone: this.phone,
                name:this.userName,
              });
              // 注册成功后的逻辑处理
              this.goToLogin();
            } else {
              this.showToastMessage(data.msg);
            }
          })
          .catch(error => {
            this.showToastMessage('注册失败');
            console.log(error);
          });
      },
      showToastMessage(message) {
        showToast(message);
      },
      goToLogin() {
        // 返回登录页面
        this.$router.push('/login');
      }
    }
  };
  </script>
  
  <style>
  .register-container {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    /* height: 100vh; */
    margin-top: 15vh; /* 调整登录框位置 */
  }
  
  .register-box {
    width: 300px;
    height: fit-content;
    padding: 5px 20px 5px;
    background-color: #f8f8f8;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    margin-top: 0;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .btn {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 5px;
  }
  
  .register-btn {
    display: block;
    width: 100%;
    margin-top: 20px;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .login-link {
    margin-top: 20px;
    font-size: 10px;
    text-align: center;
    color: #007bff;
    cursor: pointer;
    
    text-decoration: underline;
  }
  
  .login-link:hover {
    color: #007bff;
  }

  .invite-style{
    margin-left: 10px;
  }

  </style>
  