import { createRouter, createWebHistory } from 'vue-router';

// 导入组件
import UserLogin from '../views/UserLogin.vue';
import PaymentV from '../views/PaymentV.vue';
import ReferenceDownloads from '../views/ReferenceDownloads.vue';


import MainPage from '../views/MainPage.vue'
import MainSidebar from '../views/MainSidebar'
import MainContent from '../views/MainContent'

import UserRegister from '../views/UserRegister'



// 创建路由实例
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/login',
      component: UserLogin,
    },
    {
      path: '/register',
      component: UserRegister,
    },
    {
      path: '/pay',
      component: PaymentV,
    },
    {
      path: '/ref',
      component: ReferenceDownloads,
    },
    {
      path: '/',
      component: MainPage,
      children: [
        {
          path: "",
          components: {
            mainSidebar: MainSidebar,
            mainContent: MainContent,
          }
        }
      ]
    },
  ],
});

router.beforeEach((to, from, next) => {
  // 在每次路由切换之前执行
  document.title = to.meta.title || 'AI智写助手'
  next()
})

export default router;
