export function countCharacters(tmp) {
    let text = tmp;
    var chineseCount = 0;
    var punctuationCount = 0;
    var nonChineseCount = 0;
    var isInsideNonChinese = false;

    // 正则表达式匹配标点符号
    var punctuationRegex = /[。，？！、；：‘’“”（）—…《》]/;

    for (var i = 0; i < text.length; i++) {
        var currentChar = text[i];

        // 判断是否是汉字
        if (/[\u4e00-\u9fa5]/.test(currentChar)) {
            chineseCount++;
            isInsideNonChinese = false;
        }
        // 判断是否是标点符号
        else if (punctuationRegex.test(currentChar)) {
            punctuationCount++;
            isInsideNonChinese = false;
        }
        // 判断是否是非可见字符
        else if (/[^\s]/.test(currentChar)) {
            // 如果之前不是连续的非中文字符，则增加计数
            if (!isInsideNonChinese) {
                nonChineseCount++;
                isInsideNonChinese = true;
            }
        }
        // 其他情况，重置isInsideNonChinese
        else {
            isInsideNonChinese = false;
        }
    }

    // 求两个计数的总和
    return chineseCount + punctuationCount + nonChineseCount;

}