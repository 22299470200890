<template>
    <button :class="buttonClass" :disabled="isLoading" @click="handleClick">
        <span v-if="isLoading" class="button-text">{{ loadingText }}</span>
        <span v-else class="button-text">{{ buttonText }}</span>
        <span v-if="isLoading" class="loading-spinner"></span>
    </button>
</template>
  
<script>
export default {
    props: {
        isLoading: {
            type: Boolean,
            default: false
        },
        buttonText: {
            type: String,
            default: "Button"
        },
        loadingText: {
            type: String,
            default: "Loading..."
        }
    },
    computed: {
        buttonClass() {
            return {
                "loading-button": true,
                "loading": this.isLoading
            };
        }
    },
    methods: {
        handleClick() {
            console.log("handleClick called");
            // if (!this.isLoading) {
            //     this.$emit("click");
            // }
        }
    }
};
</script>
  
<style scoped>
.loading-button {
    display: inline-flex;
    align-items: center;
}

.loading-spinner {
    display: inline-block;
    width: 10px;
    height: 10px;
    border: 2px solid #ccc;
    border-top-color: #333;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-left: 4px;
}

.button-text {
    margin-right: 4px;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
</style>
  