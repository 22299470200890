<template>
<div class="container">
    <div class="left-panel">
      <label for="field">待降重内容:</label>
      <el-input
        type="textarea"
        v-model="leftContent"
        :rows="10"
        placeholder="1、简易方式：将需要降重文字所在的段落，或者至少包含其前后的一句话，共同复制进对话框。
2、精准方式：将需要降重文字所在的二级标题全部内容，复制进对话框。
3、请输入中文内容"
      ></el-input>
       
      <div class="result-content">
          <!-- <el-button class="copy-btn" @click="copyContent">复制</el-button> -->
        <LoadingButton :is-loading="isGeneratingDupContent" @click="generateDupContent" :button-text="'降重'"
              :loading-text="'降重'"></LoadingButton>
          <button class="btn-stop" @click="stopGenerateDupArticle" v-if="isGeneratingDupContent">结束生成</button>
        <!-- <div class="title">降重</div> -->
        <textarea v-model="rightContent" 
          ref="rightContentArea"
          type="textarea"
          @scroll="handleScroll('rightContentArea')"
          :rows="10"
          placeholder="降重后的内容"></textarea>
      </div>
      
    </div>
    <div class="right-panel">
      
    </div>
  </div>
</template>

<script>
import ResponseParser from '@/js/ResponseParser'
import LoadingButton from '@/components/LoadingButton.vue'
import {requestDupContent} from '@/js/api'
import {showToast, showErrorToast} from '@/js/toast'


export default {
  name: 'DuplicateReduction',
  mounted(){
    this.scrollToBottom('rightContentArea');
  },
  created(){
    this.dupParser = new ResponseParser();
    var loginStatus = localStorage.getItem('loginStatus');

      // 检查登录状态
      if (loginStatus) {
          var userData = JSON.parse(loginStatus);
          this.accessKey = userData.data.accessKey;

      } else {
          // 用户未登录，跳转到登录页
          this.$router.push('/login');
      }
  },

  
  components: {
    LoadingButton,
  },
  data() {
    return {
      leftContent: '',
      rightContent: '',
      showProgress: false,
      progress: 0,
      dupParser: null,
      isGeneratingDupContent:false,
      accessKey:null,
      isAutoScrolling:true,
    };
  },
  methods: {

    async generateDupContent() {
      if(!this.leftContent){
        //
        showToast('待降重内容不能为空');       
        return;
      }
      this.isGeneratingDupContent = true;
      this.rightContent='';
      const responseData = await requestDupContent(this.accessKey, this.leftContent);
      this.dupParser = new ResponseParser();
      this.dupParser.reset();
      if (this.dupParser.isNullCallback()) {
        this.dupParser.setCallback((responseData, done) => {
          if (done) {
            this.isGeneratingDupContent = false;
            if (this.rightContent.includes('出错啦！请重试')) {
              showErrorToast('生成内容出错');
            }
            return;
          }
          // console.log(responseData.choices[0].delta.content)
          this.rightContent += responseData.choices[0].delta.content;
          // 只有当处于自动滚动状态时才滚动到底部
          if (this.isAutoScrolling) {
            this.scrollToBottom('rightContentArea');
          }
        });
      }
      this.dupParser.parseResponseData(responseData);
    },
    stopGenerateDupArticle(){
      console.log("stopGenerateDupArticle");
      this.isGeneratingDupContent = false;
      this.dupParser.stopReadingAndExit();
      this.dupParser = null;
      this.rightContent='';
    },
    handleScroll(textareaRef) {
      const textarea = this.$refs[textareaRef];
      // 检查是否滑到最底部
      const isAtBottom = textarea.scrollTop + textarea.clientHeight === textarea.scrollHeight;

      // 如果用户手动滑动到最底部，则启动自动滚动
      if (isAtBottom && !this.isAutoScrolling) {
        this.isAutoScrolling = true;
        this.scrollToBottom(textareaRef);
      }

      // 如果用户手动滑动，则停止自动滚动
      if (!isAtBottom && this.isAutoScrolling) {
        // this.isAutoScrolling = false;
        this.isAutoScrolling = true;
      }
    },

    scrollToBottom(textareaRef) {
      const textarea = this.$refs[textareaRef];
      textarea.scrollTop = textarea.scrollHeight;
    },
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  /* background-color: aqua; */
  text-align: left;
}

.left-panel {
  flex: 1;
  /* background-color: antiquewhite; */
}

/* .left-panel .el-input__textarea {
  height: auto;
} */

.right-panel {
  flex: 1;
  /* background-color: rgb(196, 196, 214); */
}

.title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.copy-btn {
  margin-top: 10px;
}

.btn-stop{
  margin-left: 10px;
}

.result-content{
  width: auto;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
</style>
