<template>
      <div v-if="showModal" class="modal">
        <div class="modal-content">
            <div class="close-btn" @click="closeModal">x</div>
            <div class="content-panel">
              <div class="left-panel">
                  <h2>请选择套餐</h2>
                  <div class="packages-container">
                      <div
                        v-for="rechargePackage in rechargePackages"
                        :key="rechargePackage.id"
                        @click="buyPackage(rechargePackage)"
                        class="package-item"
                      >
                        <!-- <img :src="rechargePackage.image" alt="套餐图片" class="package-image" /> -->
                        <!-- <p class="package-name">{{ rechargePackage.name }}</p> -->
                        <p class="package-name">充值套餐</p>
                        <p class="package-amount">金币数：{{ rechargePackage.gold }}</p>
                        <p class="package-amount">充值金额：￥{{ rechargePackage.amount }}</p>
                        <!-- <p class="package-amount">有效期{{ rechargePackage.name }}</p> -->
                      </div>
                  </div>
              </div>  

              <div class="right-panel">
                  <!-- <h1>当前套餐</h1>
                  <img :src="purchaseCodeUrl" alt="套餐图片"/> -->
              </div>
            
            </div>
        </div>
      
      </div>
      
</template>
  
<script>
  export default {
    props: {
      showModal: {
        type: Boolean,
        required: true,
      },
      rechargePackages: {
        type: Array,
        required: true,
      },
    },
    methods: {
      closeModal() {
        this.$emit('closeModal');
      },
      buyPackage(rechargePackage) {
        this.$emit('buyPackage', rechargePackage);
      },
    },
  };
</script>
  
<style>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 背景半透明黑色 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  position: relative;
  width: fit-content;
  height: fit-content;
  background-color: rgb(224, 221, 217);
  padding: 20px;
  padding-right: 30px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  
  border-radius: 4px;
  cursor: pointer;
}

.content-panel{
  display: flex;
  flex-direction: row;
}

.right-panel{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}

.packages-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  
}

.package-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:flex-start;
  
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 20px;

  margin-left: 10px;

  width: fit-content;
  height: 200px;
  cursor: pointer;
  background-color: rgb(188, 134, 63);
}
.package-image {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}


.package-name {
  font-weight: bold;
  margin-bottom: 10px;
}

.package-amount {
  /* margin-top: 5px; */
  margin-bottom: 10px;
}
</style>