<template>
  <div class="main-sidebar">
    <div class="sidebar-navigation">
      <div
        v-for="(item, index) in sidebarItems"
        :key="index"
        :class="['sidebar-item', { 'active': selectedItem === item, 'hover': hoveredItem === item }]"
        @mouseenter="hoveredItem = item"
        @mouseleave="hoveredItem = null"
        @click="selectMenuItem(item)"
      >
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainSidebar',
  data() {
    return {
      sidebarItems: ['智写', '降重', '改写'],
      selectedItem: null,
      hoveredItem: null,
    };
  },
  created(){
    this.selectMenuItem('智写');
  },
  methods: {
    selectMenuItem(item) {
      this.selectedItem = item;
      console.log('MainSidebar click ' + item);
      this.$emit('menuItemSelected', item);
    },
  },
};
</script>

<style scoped>
.main-sidebar {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.sidebar-navigation {
  padding: 0px;
}

.sidebar-item {
  cursor: pointer;
  padding: 10px;
  color: aliceblue;
}

.sidebar-item.active {
  background-color: #333;
  color: #fff;
}

.sidebar-item.hover {
  background-color: #666;
  color: #fff;
}

.sidebar-item:hover {
  background-color: #999;
  color: #fff;
}
</style>
