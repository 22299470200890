import {sendDot} from '@/js/dot'
async function sendRequest(ak, url, data) {
    let reqUrl = 'https://musicgpt.mbmzone.com/api-thesis/' + url;
    const headers = {
        'Content-Type': 'application/json',
        accessKey: ak,
        'api-key': '1',
    };
    const response = await fetch(reqUrl, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
    });
    return response;
}


export async function requestArticleTitle(ak, focusContent, numberContent, professionalContent){
    const dotData = {
        type:'title',
        focus:focusContent,
        numTitle:numberContent,
        professional:professionalContent
    };

    sendDot(dotData);

    return sendRequest(ak, '/thesis/title', {
        focus: focusContent,
        number: numberContent,
        professional: professionalContent,
        max_tokens: 4000,
        model: 'xy-openai-gpt4',
        stream: true,
        temperature: 0.6,
        top_p: 0.5
    });
}

export async function requestArticleOutline(accessKey, professionalContent, chooseTitleContent) {
    const dotData = {
        type:'outline',
        title:chooseTitleContent,
        professional:professionalContent
    };

    sendDot(dotData);
    return sendRequest(accessKey, '/thesis/outline', {
        professional: professionalContent,
        title: chooseTitleContent,
        max_tokens: 6000,
        model: 'xy-openai-gpt4-32k',
        stream: true,
        temperature: 1,
        top_p: 0.85
    });
}


export async function requestArticleContent(ak,professionalContent, chooseTitleContent, outlineContent){
    const dotData = {
        type:'content',
        title:chooseTitleContent,
        outline:outlineContent,
        professional:professionalContent
    };

    sendDot(dotData);
    return sendRequest(ak, '/thesis/content', {
        professional: professionalContent,
        title: chooseTitleContent,
        max_tokens: 10500,
        model: 'xy-openai-gpt4-32k',
        stream: true,
        temperature: 1,
        outline: outlineContent,
        top_p: 0.85
    });
}



export async function requestDupContent(ak, argContent) {
    const dotData = {
        type:'dup',
        dup:argContent,
    };

    sendDot(dotData);
    return sendRequest(ak, '/thesis/contentDistinct', {
        content: argContent,
        max_tokens: 10000,
        model: "xy-openai-gpt4-32k",
        stream: true,
        temperature: 0.6,
        top_p: 0.85
    });
}

export async function requestModifyContent(ak, backgroundContent, suggestionContent, mainContent){
    const dotData = {
        type:'mod',
        bg:backgroundContent,
        suggest:suggestionContent,
        main:mainContent
    };

    sendDot(dotData);
    return sendRequest(ak, '/thesis/contentRewrite', {
        backgroundInfo: backgroundContent,
        suggestion: suggestionContent,
        content: mainContent,
        max_tokens: 11000,
        model: 'xy-openai-gpt4-32k',
        stream: true,
        temperature: 1,
        top_p: 0.85
      });
}