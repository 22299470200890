<template>
  <div class="article-creation">
    <!-- toast提醒 -->
      <div class="section">
        <label for="field">专业领域：</label>
        <el-input type="text" id="field" v-model="professionalContent" placeholder="例如行政管理,限定10个汉字"></el-input>
      </div>
      <div class="section">
        <label for="quantity">题目数量：</label>
        <el-input type="number" id="field" v-model="numberContent" placeholder="最多10个标题"></el-input>
        <!-- <select id="quantity" v-model="numberContent" class="quantity-select">
        <option v-for="n in quantityOptions" :value="n" :key="n" :selected="n === numberContent">{{ n }}</option>
      </select> -->
      </div>
      <div class="section">
        <label>聚焦方向：</label>
        <el-input type="text" v-model="focusContent" placeholder="例如智慧城市与数字经济,限定15个汉字"></el-input>
      </div>
      <div class="section">
        <LoadingButton :is-loading="isGeneratingTitle" @click="generateTitle" :button-text="'生成标题'"
          :loading-text="'生成标题'"></LoadingButton>
        <textarea v-model="titleContent" type="textarea" rows="4" placeholder="自动输出标题" ref="titleTextarea" 
        @scroll="handleScroll('titleTextarea')"
        ></textarea>
      </div>
      <div class="section">
        <label for="field">标题：</label>
        <el-input type="text" id="field" v-model="chooseTitleContent" placeholder="内容来源于生成的标题"></el-input>
      </div>

      <div class="section">
        <!-- <span class="tips-left">首先点击</span> -->
        <LoadingButton :is-loading="isGeneratingOutline" @click="generateOutline" :button-text="'生成大纲&概览'"
        :loading-text="'生成大纲&概览'"></LoadingButton>
        <!-- <span class="tips-right">可复制和微调</span> -->

        <textarea v-model="outlineContent" rows="9" type="textarea" placeholder="输出大纲" ref="outlineTextarea" 
        @scroll="handleScroll('outlineTextarea')"
        ></textarea>
        <span class="p-char-count">大纲字数：{{ charOutlineCount }} 字</span>
      </div>

      <div class="section">
        <!-- <span class="tips-left">然后点击</span> -->
        <span>
          <LoadingButton :is-loading="isGeneratingArticle" @click="generateArticle" :button-text="'生成正文'"
          :loading-text="'生成正文'" ></LoadingButton>
          <button class="btn-stop" @click="stopGenerateArticle" v-if="isGeneratingArticle">结束生成</button>
        </span>
        <!-- <span class="tips-right">可复制和下载</span> -->
        <textarea v-model="articleContent" rows="9" type="textarea" ref="articleTextarea" class="article" placeholder="输出正文" id="articleContentResult" 
        @scroll="handleScroll('articleTextarea')"
        ></textarea>
        <span class="p-char-count">文章字数：{{ charCount }} 字</span>
      </div>
      <div class="section">
        <span>
          <button class="download-article-btn" @click="downloadArticle">下载文章</button>
          <button class="download-article-btn" @click="copyArticle">复制文章</button>
          <button  class="download-article-btn" @click="gotoReferencePage">下载参考文献</button>
        </span>
      </div>   
    <div class="editor">
    </div>
  </div>    
  

</template>

<script>
import LoadingButton from '@/components/LoadingButton.vue';
import ResponseParser from '@/js/ResponseParser';
import ContentTimer from '@/js/ContentTimer';
import {requestArticleTitle, requestArticleContent, requestArticleOutline} from '@/js/api'
import {countCharacters} from '@/js/utils'
import {showToast, showErrorToast} from '@/js/toast'
// import { saveAs } from 'file-saver';
// import Docxtemplater from 'docxtemplater';
// import JSZip from 'jszip';
// import JSZipUtils from 'jszip-utils';
// import PizZip from 'pizzip';

export default {
  created() {
    this.checkLoginStatus();
    this.articleParser = new ResponseParser();
    this.titleParser = new ResponseParser();   
    this.outlineParser = new ResponseParser();

    this.contentTimer = new ContentTimer(1000*10);// 10s 一个时钟间隔

  },
  mounted() {
    // 在组件挂载完成后，默认将编辑框滚动到底部
    this.scrollToBottom('titleTextarea');
    this.scrollToBottom('outlineTextarea');
    this.scrollToBottom('articleTextarea');

    this.contentTimer.start();
    this.contentTimer.subscribe(()=>{
      const nowTime = Date.now();
      if((nowTime - this.startTimeForArticle) > 2*60*1000 && this.isGeneratingArticle){
        showToast("生成正文响应有点慢");
      }

      if((nowTime - this.startTimeForOutline) > 2*60*1000 && this.isGeneratingOutline){
        showToast("生成大纲响应有点慢");
      }
    });

  },
  watch:{
    articleContent(newText) {
      return this.charCount = countCharacters(newText);
    },
    outlineContent(newText){
      return this.charOutlineCount = countCharacters(newText);
    }
  },
 
  components: {
    LoadingButton,
  },
  data() {
    return {
      focusContent: '',
      numberContent: '',
      professionalContent: '',
      editable: false,

      titleContent: '',
      chooseTitleContent: '',
      articleContent: '',
      outlineContent: '',

      userName: '',
      amount: '',
      referralCode: '',
      accessKey:'',

      isAutoScrolling: true, // 标记是否处于自动滚动状态
      quantityOptions: [1, 2, 3, 4, 5], // 题目数量选项


      isGeneratingTitle: false,
      isGeneratingOutline: false,
      isGeneratingArticle: false,
      // 其他数据...

      articleParser: null,
      titleParser: null,
      outlineParser: null,

      charCount:0,
      charOutlineCount:0,

      contentTimer:null,

      startTimeForArticle: 0,
      startTimeForOutline: 0,

    };
  },
  methods: {
    handleScroll(textareaRef) {
      const textarea = this.$refs[textareaRef];
      // 检查是否滑到最底部
      const isAtBottom = textarea.scrollTop + textarea.clientHeight === textarea.scrollHeight;

      // 如果用户手动滑动到最底部，则启动自动滚动
      if (isAtBottom && !this.isAutoScrolling) {
        this.isAutoScrolling = true;
        this.scrollToBottom(textareaRef);
      }

      // 如果用户手动滑动，则停止自动滚动
      if (!isAtBottom && this.isAutoScrolling) {
        // this.isAutoScrolling = false;
        this.isAutoScrolling = true;
      }
    },

    scrollToBottom(textareaRef) {
      const textarea = this.$refs[textareaRef];
      textarea.scrollTop = textarea.scrollHeight;
    },

    async generateTitle() {
      try {
        console.log("generateTitle")
        if(this.isGeneratingArticle || this.isGeneratingOutline){
          showToast("其他任务进行中，请稍等");
          return;
        }
        if(!this.focusContent || !this.professionalContent || (this.numberContent < 1 || this.numberContent > 10)){
          showToast("专业领域、题目数量、聚焦方向均不能为空，题目数量不能超过10个");
          return;
        }
        if(this.focusContent.trim().length > 15 || this.professionalContent.trim().length > 10){
          showToast("专业领域字符数不能超过10，聚焦方向字符数不能超过15");
          return;
        }

        this.isGeneratingTitle = true;
        this.titleContent = '';
        const response = await requestArticleTitle(this.accessKey, this.focusContent, this.numberContent, this.professionalContent);
        this.titleParser.reset();
        if (this.titleParser.isNullCallback()) {
          this.titleParser.setCallback((responseData, done) => {
            if (done) {
              this.isGeneratingTitle = false;
              if (this.titleContent.includes('出错啦！请重试')) {
                showErrorToast('生成标题出错');
              }
              return;
            }
            // console.log(responseData.choices[0].delta.content)
            this.titleContent += responseData.choices[0].delta.content;
            // 只有当处于自动滚动状态时才滚动到底部
            if (this.isAutoScrolling) {
              this.scrollToBottom('titleTextarea');
            }
          });
        }
        this.titleParser.parseResponseData(response);
      } catch (error) {
        this.isGeneratingTitle = false;
        console.error('Error:', error);
      }
    },

    async generateArticle() {
      console.log("generateArticle")
      if(this.isGeneratingOutline || this.isGeneratingTitle){
        showToast("其他任务进行中，请稍等");
        return;
      }
      if(!this.professionalContent || !this.outlineContent){
          showToast("专业领域 或者 大纲为空");
          return;
      }
      if(this.professionalContent.trim().length > 10){
        showToast("专业领域字符数不能超过10");
        return;
      }
      try {
        // 启动论文生成后，设置状态为生成中，清空编辑框论文内容，启动定时器
        this.isGeneratingArticle = true;
        this.articleContent = '';
        this.startTimeForArticle = Date.now();
        const response = await requestArticleContent(this.accessKey, this.professionalContent, this.chooseTitleContent, this.outlineContent);
        this.articleParser = new ResponseParser();
        this.articleParser.reset();
        if (this.articleParser.isNullCallback()) {
          this.articleParser.setCallback((responseData, done) => {
            this.startTimeForArticle = Date.now();
            if (done) {
              this.isGeneratingArticle = false;
              if (this.articleContent.includes('出错啦！请重试') || this.articleContent.includes('由于篇幅限制') || this.articleContent.includes('由于字数限制')) {
                showErrorToast('生成论文出错，请重新生成');
              }
              return;
            }
            this.articleContent += responseData.choices[0].delta.content;
            if (this.isAutoScrolling) {
              this.scrollToBottom('articleTextarea');
            }
          });
        }
        this.articleParser.parseResponseData(response);
      } catch (error) {
        this.isGeneratingArticle = false;
        console.error('Error:', error);
      }
    },

    async generateOutline() {
      console.log("generateOutline")
      if(this.isGeneratingArticle || this.isGeneratingTitle){
        showToast("其他任务进行中，请稍等");
        return;
      }
      if(!this.professionalContent || !this.chooseTitleContent){
          showToast("专业领域 或者 标题 为空");
          return;
      }
      if(this.professionalContent.trim().length > 10 || this.chooseTitleContent.trim().length > 30){
        showToast("专业领域字符数不能超过10，文章标题字符数不能超过30");
        return;
      }
      // if(countCharacters(this.chooseTitleContent) > 30){
      //   showToast("标题数量不能超过30字，请重新填写");
      //   return;
      // }
      try {
        this.isGeneratingOutline = true;
        this.outlineContent = '';
        this.articleContent = '';

        this.startTimeForOutline = Date.now();

        const response = await requestArticleOutline(this.accessKey, this.professionalContent, this.chooseTitleContent);

        this.outlineParser.reset();
        if(this.outlineParser.isNullCallback()){
            this.outlineParser.setCallback((responseData, done) => {
              this.startTimeForOutline = Date.now();
              if (done) {
                this.isGeneratingOutline = false;
                if (this.outlineContent.includes('出错啦！请重试')) {
                  showErrorToast('生成大纲出错');
                }
                return;
              }
              this.outlineContent += responseData.choices[0].delta.content;
              if (this.isAutoScrolling) {
                this.scrollToBottom('outlineTextarea');
              }
          });
        }
        
        this.outlineParser.parseResponseData(response);
      } catch (error) {
        this.isGeneratingOutline = false;
        console.error('Error:', error);
      }
    },

    async downloadArticle() {
      if(!this.articleContent){
        showToast("文章为空");
        return;
      }
      if(this.isGeneratingArticle){
        showToast("文章生成中，不可以下载");
        return;
      }
      // const articleContent = this.articleContent;
      // const templatePath = '/template.docx';

      // JSZipUtils.getBinaryContent(templatePath, async (error, content) => {
       
      //   if (error) {
      //     console.error('无法加载模板文件:', error);
      //     return;
      //   }

      //   const zip = new PizZip(content);
      //   const doc = new Docxtemplater().loadZip(zip);
      //   doc.setData({
      //     content: articleContent
      //   });

      //   try {
      //     doc.render();
      //   } catch (error) {
      //     console.error('生成 Word 文档时发生错误:', error);
      //     return;
      //   }

      //   const generatedDoc = doc.getZip().generate({ type: 'blob' });
      //   saveAs(generatedDoc, 'article.docx');
      // });
      // var articleContent = document.getElementById("articleContentResult").value;
      // var element = document.createElement('a');
      // element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(articleContent));
      // element.setAttribute('download', 'article.txt');

      // element.style.display = 'none';
      // document.body.appendChild(element);

      // element.click();

      // document.body.removeChild(element);
      // 获取文章内容
      var articleContent = document.getElementById("articleContentResult").value;

      // 创建一个新的Blob对象，用于存储Word文档内容
      var blob = new Blob([articleContent], { type: 'application/msword' });

      // 创建一个临时URL，用于下载链接
      var url = URL.createObjectURL(blob);

      // 创建一个链接元素
      var link = document.createElement('a');
      link.href = url;
      if(!this.chooseTitleContent){
        link.download = 'article.docx'; // 设置下载的文件名 
      }else{
        link.download = this.chooseTitleContent + '.docx'; // 设置下载的文件名
      }
      

      // 模拟点击链接来触发下载
      link.click();

      // 释放URL对象
      URL.revokeObjectURL(url);
    },
    copyArticle() {
      if(!this.articleContent){
        showToast("文章为空");
        return;
      }
      const textarea = document.createElement('textarea');
      textarea.value = this.articleContent;
      textarea.setAttribute('readonly', '');
      textarea.style.position = 'absolute';
      textarea.style.left = '-9999px';
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      console.log("Text copied to clipboard");
      showToast("正文已复制");

    },
     // 在需要判断登录状态的地方调用该函数
     checkLoginStatus() {
      var loginStatus = localStorage.getItem('loginStatus');

      // 检查登录状态
      if (loginStatus) {
          // 用户已登录，可以继续进行操作
          var userData = JSON.parse(loginStatus);
          // console.log(userData);

          this.userName = userData.data.name;
          this.accessKey = userData.data.accessKey;

          // this.amount= userData.data.remainAmount;
          // this.referralCode = userData.data.inviteCode;
          // 在这里进行相关操作，例如生成标题、生成大纲、生成正文等
      } else {
          // 用户未登录，跳转到登录页
          this.$router.push('/login');
      }
    },

    gotoReferencePage(){
      let routeData = this.$router.resolve({ path: '/ref', query: { id: 1 } });
      window.open(routeData.href, '_blank');      
      // this.$router.push('/ref');

    },
    stopGenerateArticle(){
      console.log("stopGenerateArticle");
      this.isGeneratingArticle = false;
      this.articleParser.stopReadingAndExit();
      this.articleParser = null;
      this.articleContent = '';
    },
  },
};




</script>

<style scoped>
.article-creation {
  /* border: 10px solid #ccc; */
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 24px;
  margin-bottom: 1px;
  text-align: center;
}
.disclaimers{
  margin-top: 1px;
  margin-bottom: 20px;
  color: #ea3a32;
  font-size: 5px;
  font-weight: bold;

}

.tips-left{
  color: #ea3a32;
  margin-right: 5px;
  text-align: left;
}
.tips-right{
  color: #ea3a32;
  margin-left: 5px;
}

.sidebar {
  
}

.section {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  text-align: left;
}



label {
  /* font-weight: bold; */
}

input[type="text"],
select.quantity-select {
  flex: 1;
  height: 20px;
}

textarea {
  width: 100%;
  overflow: auto;
  /* 当内容超出文本框高度时显示滚动条 */
}

.editor {
  margin-left: 20px;
  /* height: 100%; */
}

.download-article-btn{
  margin-right: 5px;
}

.download-reference-link {
  margin-left: 10px;
  cursor: pointer;
  color: blue;
}



input[type="text"]::placeholder,
input[type="number"]::placeholder,
textarea::placeholder {
  opacity: 0.5;
  color: #999;
}

.btn-stop{
  margin-left: 10px;
}

.p-char-count{
  padding-left: 100px;
  font-size: 10px;
  text-align: right;

}


</style>
