import { ElMessage } from 'element-plus'
export function showToast(msg) {
    // 创建 Toast 元素
    ElMessage({
      message:msg,
      offset:160,
      type: 'warning'
    });
  }

  export function showErrorToast(msg){
    ElMessage({
      message:msg,
      offset:160,
      type: 'error'
    }); 
  }